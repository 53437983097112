<template>
  <div class="create-and-join-team-dialog">
    <div class="dialog-header">
      <v-icon
        class="back-icon"
        :size="24"
        color="black"
        @click="$emit('onCancel')"
      >
        arrow_back
      </v-icon>
      <div class="dialog-title">{{ $t("create_team") }}</div>

      <v-icon
        class="close-icon"
        :size="25"
        color="black"
        @click="$emit('onClose')"
      >
        close
      </v-icon>
    </div>

    <div class="dialog-body">
      <v-form
        class="create-and-join-team-form"
        ref="createAndJoinForm"
        lazy-validation
        @submit.prevent=""
      >
        <base-input
          v-model.trim="form.name"
          type="text"
          class="team-input"
          color="black"
          :label="$t('team_name')"
          :rules="rules.name"
          v-on:input="onChange"
          validate-on-blur
          height="36"
          :error="!!errors.name"
          :error-messages="errors.name"
        />
      </v-form>
    </div>

    <div class="dialog-footer">
      <base-button
        class="action-button"
        btn-type="mem-primary"
        @click="onCreateAndJoinForm"
      >
        {{ $t("create_and_join") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";
import BaseInput from "@/components/base/BaseInput";
import { requireValidator, censorValidator } from "@/common/validators";
import { mapActions } from "vuex";

export default {
  name: "CreateAndJoinDialog",
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
  },
  components: { BaseButton, BaseInput },
  data() {
    return {
      form: {
        name: "",
      },
      errors: {},
      rules: {
        name: [
          (val) => requireValidator(val) || this.$t("field_required"),
          (val) => censorValidator(val) || this.$t("error_team_abuse_word"),
        ],
      },
    };
  },
  methods: {
    ...mapActions([
      "createTeam",
      "assignTeamToTickets",
      "handleToolbarNotification",
    ]),
    async onCreateAndJoinForm() {
      this.errors = {};
      const { createAndJoinForm } = this.$refs;
      if (createAndJoinForm && createAndJoinForm.validate()) {
        try {
          const team = await this.createTeam({
            name: this.form.name,
            isPublic: true,
          });
          await this.assignTeamToTickets({
            ticket_ids: this.tickets,
            team_id: team.id,
          });
          this.$emit("onClose");
          this.errors = {};
          this.handleToolbarNotification({
            type: "success",
            text: "ticket_will_update",
            isVisible: true,
          });
          setTimeout(() => {
            this.handleToolbarNotification({ isVisible: false });
          }, 3000);
        } catch (e) {
          if (e.status === 422 && e.data?.errors) {
            const errors = Object.entries(e.data.errors);
            errors.forEach(([key, value]) => {
              const [msg] = value;
              this.$set(this.errors, key, this.$t(`error_team_${msg}`));
            });
          } else {
            console.error(e);
          }
        }
      }
    },
    onChange() {
      this.errors = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.create-and-join-team-dialog {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-self: center;
  position: relative;
  padding: 23px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #ffffff;
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: #000000;
  min-height: 320px;

  .dialog-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 0 0;

    // .close-icon,
    // .back-icon {
    //   &:hover,
    //   &:focus {
    //     &:after {
    //       opacity: 0;
    //     }
    //   }
    // }

    .dialog-title {
      width: 100%;
      padding: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: #000000;
    }
  }

  .dialog-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex: 1 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 0;
    text-align: left;
    color: black;

    .team-input {
      ::v-deep .v-input__slot {
        // min-height: 48px;
        .v-label {
          text-transform: capitalize;
        }
      }
    }
  }
  .dialog-footer {
    display: flex;
    width: 100%;
    margin-top: 26px;
    margin-bottom: 5px;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: center;

    .action-button {
      width: 328px;
      height: 44px;
      font-size: 14px;
      padding: 0 15px;
    }
  }

  @media screen and (max-width: 499px) {
    position: fixed;
    bottom: 0;
    right: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
